import { activeExperimentNames, DynamicConfigName, ExperimentName, GateName, LayerName } from 'shop/types/experiments'
import { useDynamicConfig, useExperiment, useFeatureGate } from '@statsig/react-bindings'
import { StatsigClient } from '@statsig/js-client'

type Experiment = ReturnType<StatsigClient['getExperiment']>

export class ExperimentsStore {
  storeName = 'ExperimentsStore'
  private client: StatsigClient
  private isActive = false

  setClient(client: StatsigClient) {
    this.client = client
    this.isActive = true
  }

  useExperiment(name: ExperimentName) {
    return useExperiment(name)
  }

  useFeatureGate(name: GateName) {
    return useFeatureGate(name)
  }

  getLayer(name: LayerName) {
    return this.client.getLayer(name)
  }

  useDynamicConfig(name: DynamicConfigName) {
    return useDynamicConfig(name)
  }

  logEvent(eventName: string, value: string, metadata?: Record<string, string>) {
    if (!this.isActive) {
      return
    }
    this.client.logEvent(eventName, value, metadata)
  }

  getExperiment = (name: ExperimentName) => {
    if (!this.isActive) {
      return {} as Experiment
    }
    return this.client.getExperiment(name)
  }

  get allActiveExperiments(): Record<string, { name: string; value: string }> {
    return activeExperimentNames.reduce((acc, name) => {
      const experiment = this.getExperiment(name)

      if (experiment.__evaluation?.is_experiment_active && experiment.__evaluation?.is_user_in_experiment) {
        acc[name] = {
          name: experiment.name,
          value: experiment.value,
        }
      }

      return acc
    }, {})
  }
}
